<template>
  <div data-animation="default" class="navbar w-nav" data-easing2="ease" data-easing="ease" data-collapse="medium"
    data-w-id="d3698d43-b79b-9e9c-96c3-2f295a189052" role="banner" data-no-scroll="1" data-duration="400"
    data-doc-height="1" style="background-color: rgba(0, 0, 0, 0);">
    <div class="container w-container">
      <a href="#" class="brand w-nav-brand"><img
        src="@/assets/logo.webp"
        loading="lazy" data-w-id="d3698d43-b79b-9e9c-96c3-2f295a189055" alt="Author
" class="navigation-logo-image-1" style="will-change: opacity; opacity: 1;">
<!-- <img
        src="https://assets.website-files.com/60accd6bf378570a40585e3a/60ae251b62145d490d31ad44_header-logo2.png"
        loading="lazy" data-w-id="d3698d43-b79b-9e9c-96c3-2f295a189056" alt="Company logo"
        class="navigation-logo-image-2" style="will-change: opacity; opacity: 0;"> -->
      </a>
   <!--  <nav role="navigation" class="nav-menu w-nav-menu">
      <div data-hover="true" data-delay="0" data-w-id="479d703b-ef0c-2cc9-8a4b-d621d6ec7c8f" class="dropdown w-dropdown"
        style="max-width: 1720px;">
        <div class="nav-link w-dropdown-toggle" id="w-dropdown-toggle-0" aria-controls="w-dropdown-list-0"
          aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0" style="color: rgb(0, 0, 0);">
          <div>Home</div>
          <div class="icon-5 w-icon-dropdown-toggle" aria-hidden="true"></div>
        </div>
        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-0" aria-labelledby="w-dropdown-toggle-0"
          style="height: 0px;"><a href="/" class="dropdown-link w-dropdown-link" tabindex="0">Home</a><a href="/home-2"
            class="dropdown-link w-dropdown-link" tabindex="0">Home 2</a><a href="/home-3"
            class="dropdown-link w-dropdown-link" tabindex="0">Home 3</a><a href="/home-4"
            class="dropdown-link w-dropdown-link" tabindex="0">Home 4</a></nav>
      </div>
      <div data-hover="true" data-delay="0" data-w-id="c943638b-9744-a5c1-1afb-6f4a4373f9c3" class="dropdown w-dropdown"
        style="max-width: 1720px;">
        <div class="nav-link w-dropdown-toggle" id="w-dropdown-toggle-1" aria-controls="w-dropdown-list-1"
          aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0" style="color: rgb(0, 0, 0);">
          <div>Services</div>
          <div class="icon-5 w-icon-dropdown-toggle" aria-hidden="true"></div>
        </div>
        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-1" aria-labelledby="w-dropdown-toggle-1"
          style="height: 0px;"><a href="/warehousing-service" class="dropdown-link w-nav-link" tabindex="0"
            style="max-width: 1720px;">Warehousing Services</a><a href="/distribution-services"
            class="dropdown-link w-nav-link" tabindex="0" style="max-width: 1720px;">Distribution Services</a><a
            href="/value-added-services" class="dropdown-link w-nav-link" tabindex="0" style="max-width: 1720px;">Value
            Added Services</a><a href="/global-coverage" class="dropdown-link w-nav-link" tabindex="0"
            style="max-width: 1720px;">Global Coverage</a></nav>
      </div>
      <div data-hover="true" data-delay="0" data-w-id="d3698d43-b79b-9e9c-96c3-2f295a189062" class="dropdown w-dropdown"
        style="max-width: 1720px;">
        <div class="nav-link w-dropdown-toggle" id="w-dropdown-toggle-2" aria-controls="w-dropdown-list-2"
          aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0" style="color: rgb(0, 0, 0);">
          <div>Company</div>
          <div class="icon-5 w-icon-dropdown-toggle" aria-hidden="true"></div>
        </div>
        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-2" aria-labelledby="w-dropdown-toggle-2"
          style="height: 0px;"><a href="/about-us" class="dropdown-link w-dropdown-link" tabindex="0">Company
            Profile</a><a href="/our-mission" class="dropdown-link w-dropdown-link" tabindex="0">Mission</a><a
            href="/testimonials" class="dropdown-link w-dropdown-link" tabindex="0">Testimonials</a><a
            href="/why-choose-us" class="dropdown-link w-dropdown-link" tabindex="0">Why choose Us?</a><a
            href="/leadership" class="dropdown-link w-dropdown-link" tabindex="0">Leadreship</a><a href="/careers"
            class="dropdown-link w-dropdown-link" tabindex="0">Careers</a><a href="/certificates"
            class="dropdown-link w-dropdown-link" tabindex="0">Our Certificates</a><a href="/partners"
            aria-current="page" class="dropdown-link w-dropdown-link w--current" tabindex="0">Our Partners</a></nav>
      </div><a href="/news" class="dropdown nav-link news-link w-nav-link"
        style="color: rgb(0, 0, 0); max-width: 1720px;">News<br>&zwj;</a>
      <div data-hover="true" data-delay="0" data-w-id="fc37b100-050c-6b74-59ce-4c08d627c3ff" class="dropdown w-dropdown"
        style="max-width: 1720px;">
        <div class="nav-link w-dropdown-toggle" id="w-dropdown-toggle-3" aria-controls="w-dropdown-list-3"
          aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0" style="color: rgb(0, 0, 0);">
          <div>Contacts</div>
          <div class="icon-5 w-icon-dropdown-toggle" aria-hidden="true"></div>
        </div>
        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-3" aria-labelledby="w-dropdown-toggle-3"
          style="height: 0px;"><a href="/location" class="dropdown-link w-dropdown-link" tabindex="0">Location</a><a
            href="/contact-us" class="dropdown-link w-dropdown-link" tabindex="0">Contact Us</a></nav>
      </div>
      <div data-hover="true" data-delay="0" data-w-id="4d19f574-dcde-1daa-f595-2057ef455d12" class="dropdown w-dropdown"
        style="max-width: 1720px;">
        <div class="nav-link w-dropdown-toggle" id="w-dropdown-toggle-4" aria-controls="w-dropdown-list-4"
          aria-haspopup="menu" aria-expanded="false" role="button" tabindex="0" style="color: rgb(0, 0, 0);">
          <div>Features</div>
          <div class="icon-5 w-icon-dropdown-toggle" aria-hidden="true"></div>
        </div>
        <nav class="dropdown-list w-dropdown-list" id="w-dropdown-list-4" aria-labelledby="w-dropdown-toggle-4"
          style="height: 0px;"><a href="https://moovit-87ad89.webflow.io/404" class="dropdown-link w-dropdown-link"
            tabindex="0">404</a><a href="/style-guide" class="dropdown-link w-dropdown-link" tabindex="0">Style
            Guide</a></nav>
      </div><a href="#contact-form" class="button w-button"><span class="button-text">Get a Quote</span></a>
    </nav> -->
    <div class="menu-button w-nav-button" style="-webkit-user-select: text;" aria-label="menu" role="button"
      tabindex="0" aria-controls="w-nav-overlay-0" aria-haspopup="menu" aria-expanded="false">
      <div data-w-id="d3698d43-b79b-9e9c-96c3-2f295a189072" class="icon-4 w-icon-nav-menu"
        style="color: rgb(255, 255, 255);"></div>
    </div>
  </div>
  <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0"></div>
</div></template>
<style lang="scss" scoped>
.navbar {
    position: absolute;
}
.navbar {
    z-index: 9999;
    width: 100%;
    min-height: 88px;
    color: transparent;
    background-color: transparent;
    border-bottom: 1px solid rgba(255,255,255,.2);
    display: flex;
    position: fixed;
    top: 0%;
    bottom: auto;
    left: 0%;
    right: 0%;
    box-shadow: 7px 7px 20px rgba(0,0,0,.04);
}
.w-nav {
    z-index: 1000;
    background: #ddd;
    position: relative;
}
.w-nav:before, .w-nav:after {
    content: " ";
    grid-area: 1/1/2/2;
    display: table;
}
.w-nav:after {
    clear: both;
}
.container {
    width: 100%;
    max-width: 1720px;
    min-height: 88px;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
}
.w-container {
    max-width: 940px;
}
.w-container:before, .w-container:after {
    content: " ";
    grid-area: 1/1/2/2;
    display: table;
}
.w-container:after {
    clear: both;
}
.brand {
    width: auto;
    height: 100%;
    max-height: 50px;
    min-width: 150px;
    flex: 0 auto;
    justify-content: flex-start;
    align-items: center;
    display: flex;
}
a:-webkit-any-link {
    color: -webkit-link;
    cursor: pointer;
    text-decoration: underline;
}
.w-nav-brand {
    float: left;
    color: #333;
    text-decoration: none;
    position: relative;
}
.navigation-logo-image-1 {
    max-height: 50px;
    opacity: 1;
    position: absolute;
}
img {
    max-width: 100%;
}
img {
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}
img {
    border: 0;
}
.navigation-logo-image-2 {
    max-height: 50px;
    min-width: 100px;
    opacity: 0;
    display: inline-block;
    position: absolute;
}
.nav-menu {
    width: 100%;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 0;
    padding-left: 20px;
    display: flex;
    position: static;
}
.w-nav-menu {
    float: right;
    position: relative;
}
article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}
@media only screen and (max-width: 600px) {
.container {
    flex-direction: row-reverse;
    justify-content: space-between;
}
.w-container {
    max-width: 728px;
}
.brand {
    text-align: right;
    flex: 0 auto;
    justify-content: flex-end;
    margin-left: 75%!important;
    display: flex;
}
.navigation-logo-image-2 {
    display: block;
}
.w-nav[data-collapse=medium] .w-nav-menu {
    display: none;
}
.nav-menu {
    z-index: 100;
    width: 320px;
    height: auto;
    min-width: 100%;
    background-color: #fff;
    padding-left: 0;
    position: absolute;
}
}

</style>
